<template>
  <div class="indexScreen" id="screenTotal">
    <div id="myMap">
      <MapContainer :mapData="mapData"  :key="componentKey"  v-if="mapData.centerPosition && mapData.mapName"></MapContainer>
      
     <div class="link-btn">
       <div class="link-btn-item" v-for="(item,index) in linkList" :key="index" v-show="item.show == 1" @click="handleLink(item.url)">{{ item.text }}</div>
    </div>
    </div>

    <div id="header">
      <p id="header_title" :style="{'font-size':bigTitleSize}">{{ nameScreen }}</p>
      <div class="date">
        <img class="sun" src="../../../assets/index/sun.png"/>
        <span>{{ text }}</span>
        <span>{{ temp }}℃</span>
        <!-- <span>{{windDir}}{{windScale}}级</span> -->
      </div>
      <div class="weather">
        <!-- <span>{{week}}</span> -->
        <span>{{ nowDate }}</span>
      </div>
      <div class="user" @click="toBack()">
        <img src="../../../assets/index/user.png" />
        <span>{{ user }}</span>
      </div>
    </div>
    
    <div id="main">
      <div id="mainBox">
        <div id="lefts" :class="getFlag">
          <div class="leftBoxs">
            <div class="villageInfo">
              <div class="boxTitile">
                <span>基地介绍</span>
                <el-select
                  v-model="base"
                  class="base-select"
                  :popper-append-to-body="false"
                  @change="handlerSelect"
                >
                  <el-option
                    class="option"
                    v-for="item in baseList"
                    :key="item.id"
                    :label="item.baseName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="jidi">
                <div class="carousel">
                  <el-carousel
                    :interval="3000"
                    :autoplay="false"
                    indicator-position="outside"
                  >
                    <el-carousel-item
                      v-for="(item, index) in pictureList"
                      :key="index"
                    >
                      <!-- <div class="imgBox"> -->
                      <img :src="item" alt="" @click="carouselChange(index)" />
                      <!-- </div> -->
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <div class="jidi-text" style="padding-top: 10px;">
                  {{ baseObj.introduce }}
                </div>
              </div>
            </div>
            <div class="survey">
              <div class="boxTitile">
                <span>设备在线</span>
              </div>
              <!-- <div class="surveyBox">
                <div class="holdsAll">
                  <p>
                    设备总数： <span>{{ totalNumber }}</span>
                  </p>
                </div>
                <div class="areaAll">
                  <p>
                    在线：<span class="total-color">{{ onlineNumber }}</span>
                  </p>
                </div>
                <div class="peopleAll">
                  <p>
                    离线：<span>{{ offlineNumber }}</span>
                  </p>
                </div>
              </div> -->
              <div class="middle-text">设备总数 <br> {{ deviceTotal }}台</div>
              <div id="echart" style="width: 100%; height: 23vh"></div>
            </div>
            <div class="partyBuilding">
              <div class="boxTitile">
                <span>报警记录</span>
              </div>
              <div class="partyBox">
                <div class="leftCard">
                  <div class="member-card card-bg">
                    <div class="memberAll">
                      <p class="memberTop">序号</p>
                    </div>
                    <div class="fullMember">
                      <p class="memberTop">报警时间</p>
                    </div>
                    <div class="memberTwo">
                      <p class="memberTop">报警设备</p>
                    </div>
                    <div class="memberTwo">
                      <p class="memberTop">报警值</p>
                    </div>
                  </div>
                  <div class="alarm-scroll">
                    <div class="member-card member-bg" v-for="(item,i) in warnList" :key="item.id">
                      <div class="memberAll">
                        <p class="memberTop">{{ i+1 }}</p>
                      </div>
                      <div class="fullMember">
                        <p class="memberTop">{{ item.createTime}}</p>
                      </div>
                      <div class="memberTwo">
                        <p class="memberTop">{{ item.deviceName }}</p>
                      </div>
                      <div class="memberTwo">
                        <p class="memberTop alarm-num">{{ item.warnData}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="center">
          <div class="between">
            <div v-show="insectShow == 1"  class="villageInfo" style="width: 92%; margin-right: 3%">
              <div class="boxTitile">
                <span>虫情监测数据</span>
<!--                <el-select
                    v-model="soildName"
                    :popper-append-to-body="false"
                    class="base-select"
                    @change="chooseInsect"
                >
                  <el-option
                      v-for="item in insectList"
                      :key="item.deviceNum"
                      :label="item.deviceName"
                      :value="item.deviceNum"
                  ></el-option>
                </el-select>-->
              </div>
              <div class="jidi arrow" style="margin-top: 10px;">
                <div class="carousel-detail">
                  <img
                    :src="
                      'http://insect.sennor.net:1881/openFile/' +
                      insectObj.taggingImgUrlCompress
                    "
                    alt=""
                  />
                </div>
                <div class="jidi-text">
                  <div class="equit">
                    <span class="arrow-bg"></span
                    ><span class="arrow-key">设备名称:</span
                    ><span class="arrow-val">{{ insectObj.deviceName }}</span>
                  </div>
                  <div class="equit">
                    <span class="arrow-bg"></span
                    ><span class="arrow-key">设备编号:</span
                    ><span class="arrow-val">{{ insectObj.deviceNumber }}</span>
                  </div>
                  <div class="equit">
                    <span class="arrow-bg"></span
                    ><span class="arrow-key">负责人:</span
                    ><span class="arrow-val">{{ baseObj.basePrincipal }}</span>
                  </div>
                  <div class="equit">
                    <span class="arrow-bg"></span
                    ><span class="arrow-key">经纬度:</span
                    ><span class="arrow-val">{{
                      insectObj.deviceLocation
                    }}</span>
                  </div>
                  <div class="equit">
                    <span class="arrow-bg"></span
                    ><span class="arrow-key">识别内容:</span
                    ><span class="arrow-val" :title="insectObj.results">{{
                      insectObj.results
                    }}</span>
                  </div>
                  <div class="update">{{ insectObj.newTime }}更新</div>
                </div>
              </div>
            </div>
             <div  v-show="ctrolShow == 1"  class="villageInfo" style="width: 92%">
              <div class="boxTitile">
                <span>灌溉控制设备</span>
              </div>
              <div class="jidi guan">
                <div
                  class="guan-list"
                  v-for="(item, i) in irrigationList"
                  :key="item.itemId"
                >
                  <div class="content">
                    <div>
                      <p>
                        <span class="number">{{ item.name }}</span>
                      </p>
                    </div>
                    <div class="switch">
                      <span class="openBtn" @click="changStatus('255', item.dataPointRelId)"
                        >开启
                      </span>
                      <span class="clsBtn" @click="changStatus('00', item.dataPointRelId)"
                        >关闭
                      </span>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div id="rights" :class="!threeFlag3 ? 'translations2' : ''">
          <div class="rightBox">
            <div class="villageInfo">
              <div class="boxTitile">
                <span>土壤监测数据</span>
                <el-select
                  v-model="soildName"
                  :popper-append-to-body="false"
                  class="base-select"
                  @change="chooseSoil"
                >
                  <el-option
                    v-for="item in soilList"
                    :key="item.deviceNum"
                    :label="item.deviceName"
                    :value="item.deviceNum"
                  ></el-option>
                </el-select>
              </div>
              <div class="jidi">
                <div class="land">
                  <div class="land-list">
                    <div
                      class="equitment"
                      v-for="(item, i) in soildObj"
                      :key="i"
                    >
                      <div class="text-gray">{{ item.name }}</div>
                      <div class="text-white">
                        <span class="number">{{ item.data }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="survey">
              <div class="boxTitile">
                <span>气象设备</span>
                <el-select
                    v-model="weatherName"
                    :popper-append-to-body="false"
                    class="base-select"
                    @change="chooseWeather"
                >
                  <el-option
                      v-for="item in weatherList"
                      :key="item.deviceNum"
                      :label="item.deviceName"
                      :value="item.deviceNum"
                  ></el-option>
                </el-select>
              </div>
              <div class="common-box">
                <div class="land-list">
                  <div
                    class="equitment"
                    v-for="(item, i) in weatherObj"
                    :key="i"
                  >
                    <div class="land-icon icon1"><img :src="imgObj[i]" /></div>
                    <div class="land-value">
                      <div class="text-gray">{{ item.name }}</div>
                      <div class="text-white">
                        <span class="number">{{ item.data }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="partyBuilding">
              <div class="boxTitile">
                <span>监控摄像头</span>
                <el-select
                    v-model="cameraName"
                    :popper-append-to-body="false"
                    class="base-select"
                    @change="chooseVideo"
                >
                  <el-option
                      v-for="item in cameraList"
                      :key="item.deviceNum"
                      :label="item.deviceName"
                      :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="partyBox">
                <div class="imgInfo">
                  <dataSelect  v-if="videoList.length>0" :optionList="videoList" v-model="video" @onSelectChange="chooseVideo"></dataSelect>
                  <div class="carouselBig">
                    <video
                        v-if="videoUrl"
                        id="my-video"
                        ref="myVideo"
                        class="video-js vjs-default-skin box"
                        controls
                        preload="auto"
                        loop
                        muted
                        style="height: 100%; width: 100%; object-fit: fill"
                    >
                      <source :src="videoUrl" type="rtmp/flv" />
                    </video>
                    <div v-else>暂无监控</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottoms"></div> <img v-else :src="imgList[indexs]" alt="">   type="application/x-mpegURL" -->
    <!-- 图片弹框 -->
    <div class="imgBigBox" v-if="imgFlag">
      <div class="popUpTop">
        <span>乡村图片</span>

        <span class="closeCss" @click="closeBox">x</span>
      </div>
      <div class="imgInfo">
        <div class="carouselBig">
          <video
            v-if="indexs == 0"
            id="my-video"
            class="video-js vjs-default-skin box"
            controls
            preload="auto"
            muted
            style="height: 100%; width: 100%; object-fit: fill"
          >
            <source src="" type="application/x-mpegURL" />
          </video>
          <img v-else :src="imgList[indexs]" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Map from "../dalixian/map.vue";
import * as echarts from "echarts";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "videojs-contrib-hls";
import MapContainer from "@/views/bigScreen/zonghe/MapContainer";

export default {
  components: { MapContainer, Map },
  data() {
    return {
      dates: "",
      week: "",
      weatherKey: "5a0bc17ca9a643dc84fee66d458770ce",
      weathers: [],
      text: "",
      city:"",
      county:"",
      temp: "12-18",
      windDir: "",
      windScale: "",
      imgList: [],
      bigTitleSize:"18px",
      pictureList: [],
      totalArea: "",
      partyMemberNumber: "",
      officialPartyMemberNumber: "",
      probationaryPartyMemberNumber: "",
      totalNumber: "",
      onlineNumber: "",
      offlineNumber: "",
      onlineList: [],
      offlineList: [],
      videoList:[],
      permanentPeoples: "",
      migrantPopulation: "",
      threeFlag1: 0,
      threeFlag2: 1,
      threeFlag3: true,
      imgFlag: false,
      indexs: 0,
      myPlayer: null,
      base: "",
      value1: "",
      user: "进入后台",

      switchList: [
        require("../../../assets/index/open.png"),
        require("../../../assets/index/close.png"),
      ],
      markersList: [],
      flood: [
        { title: "总流量", value: 224, status: 0 },
        { title: "通道一流量", value: 224, status: 0 },
        { title: "通道二流量", value: 224, status: 0 },
        { title: "通道三流量", value: 224, status: 1 },
      ],
      token: "",
      deviceNumber: "",
      baseList: [],//基地列表
      baseObj: {},
      allDevice: {},//所有设备wuq定义：{devicenum:info}
      allWeather:[],//wq定义所有气象设备
      allSolid:[],//wq定义所有土壤设备
      allWater:[],//wq定义所有水质设备
      soilList: [],//基地对应土壤设备列表
      weatherList:[],//基地对应气象设备列表
      meteorList: [],//所有气象设备
      waterList:[],//基地对应水质设备列表
      insectList: [],//基地对应虫情设备列表
      cameraList: [],//基地对应摄像头设备列表
      playVideoList: [],//所有摄像头设备，暂无用
      irrigationList: [],
      soildObj: [],//土壤--当前显示数据
      meteorObj: [],//气象--当前显示数据
      weatherObj: [],//气象--当前显示数据
      waterObj: [],//水质--当前显示数据
      insectObj: {
        deviceName: "",
        deviceLocation: "",
      },
      warnList: [],
      SecretObj: {},
      videoUrl: "",
      video:"",
      soildName: "",
      weatherName: "",
      cameraName: "",
      nowDate: "",
      dataTimer: null,
      imgObj: [
        require("../../../assets/index/heat.png"),
        require("../../../assets/index/humidity.png"),
        require("../../../assets/index/windspeed.png"),
        require("../../../assets/index/trend.png"),
        require("../../../assets/index/atmos.png"),
        require("../../../assets/index/beam.png"),
        require("../../../assets/index/rainfall.png"),
        require("../../../assets/index/radiation.png"),
        require("../../../assets/index/humidity.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
        require("../../../assets/index/i.png"),
      ],
      nameScreen: "",
      deviceTotal: 0,
      productConfig: {
        header: ['任务名称 ', '执行人', '时间'],
        data:[],
        align: ['center','center','center',]
      },
      linkList:[{
        show:1,
        text: '水肥灌溉',
        url: '/irrigateControl'
      },{
        show:1,
        text: '气象监测',
        url: '/weatherScreen'
      },{
        show:1,
        text: '土壤墒情',
        url: '/soilScreen'
      },{
        show:1,
        text: '虫情监测',
        url: '/insectScreen'
      },{
        show:1,
        text: '溯源管理',
        url: '/agroTrace'
      },{
        show:1,
        text: '视频监控',
        url: '/monitScreen'
      }],
      ctrolShow:0,
      insectShow:0,
      mapData:{
        centerPosition: '',
        mapName:'', // 地图名称
        polygonWkt: '',  // 图形json
        zoom: ''
      },
      componentKey: 1,
     
    };
  },
  beforeMount() {
    window.localStorage.setItem("screenType", 0);
  },
  beforeDestroy() {
    if (this.$refs.myVideo) {
      this.$refs.myVideo.load();
    }
  },
  async mounted() {
    await this.getBaseList();
    await  this.getVideo();
    await this.getPowerName();
    this.getInsectManage();
    this.getWarnLog();
     this.getSecret()
    this.currentTime();
  },
  methods: {
    resizeBigTitle(){
      let titLength = this.nameScreen.length;
      if (titLength<=8){
        this.bigTitleSize="40px";
      }else if (titLength<=10){
        this.bigTitleSize="35px";

      }else{
        this.bigTitleSize="22px";

      }
    },
   async getPowerName() {
    await this.$get("/largeScreenManagement/getLargeScreenManagements", {
        page: 1,
        size: 100,
      }).then((res) => {
        if (res.data.state == "success") {
          this.nameScreen = res.data.datas[0].name;
          this.mapData.zoom = res.data.datas[0].zoom;
          this.linkList[0].show=res.data.datas[0].btn1==0?0:1;
          this.linkList[1].show=res.data.datas[0].btn2==0?0:1;
          this.linkList[2].show=res.data.datas[0].btn3==0?0:1;
          this.linkList[3].show=res.data.datas[0].btn4==0?0:1;
          this.linkList[4].show=res.data.datas[0].btn5==0?0:1;
          this.linkList[5].show=res.data.datas[0].btn6==0?0:1;
          this.insectShow=res.data.datas[0].btn7==0?0:1;
          this.ctrolShow=res.data.datas[0].btn8==0?0:1;
          this.city = res.data.datas[0].city;
          this.county = res.data.datas[0].county;
          window.localStorage.setItem('screenRouter', res.data.datas[0].path);

          if (this.baseList.length == 0) {
            this.mapData.centerPosition = res.data.datas[0].location
          }
          this.mapData.mapName = res.data.datas[0].userName
          this.resizeBigTitle();
          this.requestLookup();
        }
      });
    },
    toBack() {
      window.localStorage.setItem("marking", "5");
      this.$router.push("/backHome");
    },
    toMonit() {
      this.$router.push("/monitScreen");
    },
    toOne(){
      this.$router.push("/oneScreen");
    },
    currentTime() {
      this.dataTimer = setInterval(() => {
        this.getDate();
      }, 1000);
    },
    //获取所有摄像头
    getVideo() {
      this.$get("/cameraManage/page", { page: 1, size: 1000,limit:1000 }).then((res) => {
        if (res.data.state == "success") {
          //console.log("所有摄像头设备：",res.data)
          res.data.datas.forEach((item) => {
            let d='cameraId_'+item.id;
            this.allDevice[d]=item;
          });
        }else{
          this.$message.info("账号暂未绑定摄像头")
        }
      });
    },
    // 查询基地列表
    async  getBaseList() {
      const data = {
        page: 1,
        size: 100,
      };
      await this.$post("/baseManagement/getBaseManagements", data).then((res) => {
        if (res.data.state == "success") {
          this.baseList = res.data.datas;
          if (res.data.datas && res.data.datas.length>0) {
            this.base = res.data.datas[0].id;
            this.mapData.centerPosition = res.data.datas[0].centerPos
            this.mapData.polygonWkt =  res.data.datas[0].polygonWkt
            const imgList =  res.data.datas[0].baseImg ? JSON.parse(res.data.datas[0].baseImg) : [];
            imgList.forEach(v=>{
              this.pictureList.push("https://public.half-half.cn/" + v);//初始化第一个基地的图片
            })
            this.baseObj = res.data.datas[0];//初始化显示第一个基地信息
          }
          this.getOverview(this.baseObj);//设备在线率
          this.getVillage();//获取所有设备及数据

        }
      });
    },
    // 获取账号所有设备信息
    getVillage() {
      this.$get("/deviceManage/getDeviceInfos", { page: 1, size: 10000,limit:10000 }).then(
          (res) => {
            if (res.data.state == "success") {
              res.data.datas.forEach((item) => {
                const d = item.deviceNum;
                this.allDevice[d] = item;//所有传感器设备信息，用于.deviceNum形式查询设备信息

              });
              //console.log("所有传感器设备：",this.allDevice)
              this.changeWeatherModule(0);//初始化显示第一个基地的气象下拉
              this.changeSolidModule(0)//初始化显示第一个基地的土壤下拉
              //this.changeWaterModule(0)//初始化显示第一个基地的水质下拉
              this.changeCameraModule(0)//初始化显示第一个基地的摄像头下拉
            }
          }
      );
    },
    chooseSoil(val) {
      this.soildObj=this.getDeviceData(val);
      this.soildName=this.allDevice[val].deviceName;
      //console.log("选择土壤设备：",this.soildObj)
    },
    chooseWater(val) {
      this.waterObj=this.getDeviceData(val);
      this.waterName=this.allDevice[val].deviceName;
      //console.log("选择水质设备：",this.waterObj)
    },
    chooseWeather(val) {
      this.weatherObj=this.getDeviceData(val);
      this.weatherName=this.allDevice[val].deviceName;
      //console.log("选择气象设备：",this.weatherObj)
    },
    chooseVideo(val){
      this.getVideoUrl(val);
      let d='cameraId_'+val;
      this.cameraName=this.allDevice[d].deviceName;
      //console.log("选择摄像头设备：",val)
    },
    chooseInsect(val){
      this.getDeviceData(val);
    },
    changStatus(msg, id) {
      this.$get(`/deviceCtrl/dataPoint`, {
        deviceNum: this.SecretObj.deviceNumber,
        dataPointRelId: id,
        msg,
      }).then((res) => {
        if (res.data.state == "success") {
          this.$message.success("操作成功");
        }
      });
    },
    handlerSelect(val) {//切换基地
      //console.log(this.baseList)
      this.baseList.forEach((item,index) => {
        if (val == item.id) {
          this.baseObj = item;//当前基地所有信息
          this.mapData.centerPosition = item.centerPos;//切换基地中心点
          this.mapData.polygonWkt = item.polygonWkt//展示基地矢量图形
          this.componentKey += 1;//地图
          this.changeBaseList(item)//切换基地图片
          this.changeSolidModule(index);//切换土壤模块
          //this.changeWaterModule(index);//切换水质模块
          this.changeWeatherModule(index);//切换气象模块
          this.changeCameraModule(index);//切换摄像头模块
          this.getOverview(this.baseObj);//切换设备总览
        }
      });
    },
    //基地切换土壤设备
    changeSolidModule(baseIndex){
      this.soilList=[];
      let base=this.baseList[baseIndex]
      let deviceNums=JSON.parse(base.soilDevice);
      if(deviceNums.length>0){
        for (let i = 0; i < deviceNums.length; i++) {
          let item=deviceNums[i];
          this.soilList.push(this.allDevice[item])
        }
        //console.log("当前土壤设备列表：",this.soilList)
        this.chooseSoil(this.soilList[0].deviceNum)
      }


    },

    //基地切换气象设备
    changeWeatherModule(baseIndex){
      this.weatherList=[];
      let base=this.baseList[baseIndex]

      let deviceNums=JSON.parse(base.weatherDevice);
      if(deviceNums.length>0){
        for (let i = 0; i < deviceNums.length; i++) {
          let item=deviceNums[i];
          //console.log(this.allDevice[item])
          this.weatherList.push(this.allDevice[item])
        }
        //console.log("当前气象设备列表：",this.weatherList)
        this.chooseWeather(this.weatherList[0].deviceNum)

      }

    },
    //基地切换水质设备
    changeWaterModule(baseIndex){
      this.waterList=[];
      let base=this.baseList[baseIndex]
      let deviceNums=JSON.parse(base.waterDevice);
      if(deviceNums.length>0){
        for (let i = 0; i < deviceNums.length; i++) {
          let item=deviceNums[i];
          this.waterList.push(this.allDevice[item])
        }
        //console.log("当前水质设备列表：",this.waterList)
        this.chooseWater(this.waterList[0].deviceNum)
      }

    },
    //基地切换摄像头设备
    changeCameraModule(baseIndex){
      this.cameraList=[];
      let base=this.baseList[baseIndex]
      let deviceNums=JSON.parse(base.cameraDevice);
      if(deviceNums.length>0){
        for (let i = 0; i < deviceNums.length; i++) {
          let item="cameraId_"+deviceNums[i];
          this.cameraList.push(this.allDevice[item])
        }
        //console.log("当前摄像头设备列表：",this.cameraList)
        this.chooseVideo(this.cameraList[0].id)//默认播放第一个
      }else{
        this.cameraName="无设备"
        this.$message.info("基地未绑定摄像头设备")
      }

    },
    //设备详情
    getDeviceInfo(val) {
      const obj = {};
      this.$get(`/deviceManage/getDeviceSensor/${val}`, {}).then((res) => {
        if (res.data.state == "success") {
          obj = res.data.data;
        }
      });
      return obj;
    },
    //告警
    getWarnLog(){
      this.$get(`/warnManage/pageWarnLogs`,{ page:1}) .then((res) => {
        if(res.data.state == 'success'){
          //console.log(res.data,'告警详情')
          this.warnList=res.data.datas
        }
      })
    },
// 切换基地
    changeBaseList(item){
          //console.log("切换基地为：",item);
          if(item.baseImg.length) {
            this.pictureList=[];//清空基地图片
            const imgList =  item.baseImg ? JSON.parse(item.baseImg) : [];
            imgList.forEach(v=>{
              this.pictureList.push("https://public.half-half.cn/" + v);//初始化第一个基地的图片
            })
            //console.log("基地图片：",this.pictureList)

          }
          this.getOverview(this.baseObj)//更新设备统计模块


    },

    //获取展示设备数据
    getDeviceData(dev){

      if(dev == null){
        return [];
      }else{
        let dataList2 = [];
        let datas = this.allDevice[dev];
        let keys = [];
        let data = [];
        if(datas.hasOwnProperty("realData")){
          let realData = datas.realData.split("|");
          for(let a = 0; a < realData.length; a++){
            keys.push(realData[a].split("·")[0]);
            data.push(realData[a].split("·")[1]);
          }
          for(let i = 0; i < keys.length; i++){
            dataList2.push({name: keys[i], data: data[i]});
          }
          //console.log('设备号返回数据：',dataList2);
        }
        return dataList2;
      }
    },

    //获取虫情设备
    getInsectManage() {
      this.$get("/insectManage/page", { page: 1, size: 100 }).then((res) => {
        if (res.data.state == "success") {
          if (res.data.datas.length != 0) {
            res.data.datas.forEach((item) => {
              this.insectList.push(item);
              item["deviceLocation"] = item.latitudeAndLongitude;
            });
          }
          if (this.insectList.length) {
            this.getInsect(res.data.datas[0].deviceNumber);
            this.$set(this.insectObj, "deviceName", this.insectList[0].name);
            this.$set(
              this.insectObj,
              "deviceLocation",
              this.insectList[0].latitudeAndLongitude
            );
          } else {
            this.$set(this.insectObj, "deviceName", "");
            this.$set(this.insectObj, "deviceLocation", "");
          }
        }
      });
    },

    getPointInfoFor(deviceNumber) {
      this.$get("/deviceCtrl/pageDataPointInfoForCusdeviceNo", {
        page: 1,
        size: 100,
        deviceNum: deviceNumber,
      }).then((res) => {
        if (res.data.state == "success") {
          this.irrigationList = res.data.data.cusdeviceDataPointList;
        }
      });
    },
    getSecret() {
      this.$get("/deviceCtrl/getSecret").then((res) => {
        if (res.data.state == "success") {
          //console.log(res.data, "getSecret");
          this.SecretObj = res.data.data;
          this.getPointInfoFor(res.data.data.deviceNumber);
        }
      });
    },
    //获取摄像头url
    getVideoUrl(deviceNumId) {
      this.$get(`/cameraManage/realPlay/${deviceNumId}?protocol=2`).then(
        (res) => {
          if (res.data.state == "success") {
            //console.log(res.data.datas, "====获取摄像头地址");
            this.videoUrl = res.data.data.url;
            // this.$refs.myVideo.src=res.data.data.url
            const that = this;
            setTimeout(() => {
              that.lookCamera(res.data.data.url);
            }, 2000);
          }else{
            this.$message.error("摄像头播放地址获取失败，请检查设备")
          }
        }
      );
    },



    getDetail(id) {
      const obj = {};
      this.$get(`/insectManage/detail/${id}`, {}).then((res) => {
        if (res.data.state == "success") {
          obj = res.data.data;
        }
      });
      return obj;
    },

    // 获取虫情设备信息
    getInsect(deviceNumber) {
      this.$get("/insectManage/pageHist", {
        page: 1,
        size: 100,
        deviceNumber: deviceNumber,
      }).then((res) => {
        if (res.data.state == "success") {
          this.insectObj = Object.assign({}, this.insectObj, res.data.datas[0]);
          //console.log(this.insectObj, "=====虫情", res.data.datas[0]);
        }
      });
    },

    closeBox() {
      this.imgFlag = false;
    },
    getFlag() {
      return !this.threeFlag3 ? "translations" : "";
    },
    carouselChange(i) {
      this.imgFlag = true;
      this.indexs = i;
      if (i == 0) {
        // this.lookCamera();
      }
    },

    // 获取任意时间
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds);

      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "/" + tMonth + "/" + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    // 获取星期
    getCurrentWeek() {
      const days = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      const date = new Date();
      const index = date.getDay(); // 0-6,0 表示星期日
      this.week = days[index];
    },
    // 天气
    requestLookup() {
      this.$axios({
        method: "GET",
        url: "https://geoapi.qweather.com/v2/city/lookup",
        params: { adm:this.city, location:this.county, key: this.weatherKey },
      }).then((res) => {
        if (res.status == 200) {
          let { location } = res.data;
          this.requestweather7d(location);
        }
      });
    },
    // 实时天气
    requestweather7d(location) {
      this.$axios({
        method: "GET",
        url: "https://devapi.qweather.com/v7/weather/now",
        params: { location: location[0].id, key: this.weatherKey },
      }).then((res) => {
        if (res.status == 200) {
          this.weathers = res.data.now;
          this.text = res.data.now.text;
          this.temp = res.data.now.temp;
          this.windDir = res.data.now.windDir;
          this.windScale = res.data.now.windScale;
        }
      });
    },
    lookCamera(src) {
      this.$nextTick(() => {
        var myVideo = this.$refs.myVideo;
        this.myPlayer = videojs(myVideo, {
          controls: true, //控件显示
          bigPlayButton: true,
          autoplay: "muted",
          preload: "auto",
          hls: true,
        });
        this.myPlayer.src({
          src: src,
          type: "application/x-mpegURL",
        });
        //this.myPlayer.play();
        // this.videoArr.push(this.myPlayer);
      });
    },
    // lookCamera(src) {
    //   this.$nextTick(() => {
    //     this.myPlayer = videojs("my-video", {
    //      // autoplay: true, //自动播放
    //       controls: true, //控件显示
    //       bigPlayButton: true,
    //       autoplay: 'muted',
    //       preload: 'auto',
    //       //hls: true,
    //     });
    //     /* this.myPlayer.src({
    //       src: src,
    //       type:'rtmp/flv',
    //      // type: "application/x-mpegURL"
    //     });
    //     this.myPlayer.play(); */
    //   })
    // },

    // 获取在线设备
    getOverview(item) {
      this.$get("/dptj/sbzl", {
        //key:{id:item.id}
      }).then((res) => {
        if (res.data.state == "success") {
          let datas = res.data;
          //console.log(datas, "在线设备");

          this.initEcharts(datas);
        }
      });
    },
    initEcharts(seriesData) {
      const arrList = [
        {
          text: "气象",
          value: 1,
        },
        {
          text: "灌溉控制",
          value: 2,
        },
        {
          text: "土壤",
          value: 3,
        },
        {
          text: "水质",
          value: 4,
        },
        {
          text: "摄像头",
          value: 5,
        },
        {
          text: "虫情",
          value: 6,
        },
        {
          text: "孢子",
          value: 7,
        },
      ];
      this.deviceTotal = seriesData.totalNumber
      
      const lastData = seriesData.data.filter(w=>!!w.device_type).map((v) => {
        return {
          value: v.total,
          name: arrList.filter((m) =>m.value == v.device_type).length>0 ? arrList.filter((m) =>m.value == v.device_type)[0].text + '设备' : v.device_type + ' 设备',
        };
      });
      var myChart = echarts.init(document.getElementById("echart"));
      let option = {
        color: ['#429CB7','#FAC37F','#22C888','#7586D9','#3eb4ff',"#edf249"],
        tooltip: {
          show:false,
          trigger: "item",
        },
        legend: {
          show:false,
          bottom: "5%",
          align: 'left',
          right: 0,
          orient: 'vertical',
          textStyle:{
            color: '#fff'
          }
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["50%", "80%"], 
            label: {
              show: true,
              fontSize: 12,
              formatter: '{b}\n{c}台 {d}%',
              textShadowBlur: 0,
              color: '#fff',
              lineHeight: 20
            },
            data: lastData,
            avoidLabelOverlap: true,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      myChart.off("click");
      myChart.on("click", (param) => {
        //console.log(param);
      });

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    //获取当前时间
    getDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
      let day = date.getDate(); // 日
      day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
      let week = date.getDay(); // 星期
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second}  `;
    },
    handleLink(url){
      this.$router.push(url);
    }
  },
};
</script>

<style src="../../../style/font/font.css" scoped></style>
<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #fff;
}

#screenTotal {
  position: relative;
  width: 100%;
  height: 100%;

  // background-size: 100% 100%;
  // background-repeat: no-repeat;
}

#header {
  width: 100%;
  height: 90px;
  position: relative;

  text-align: center;
  overflow: hidden;
  z-index: 45;

  p {
    font-size: 40px;
    background: linear-gradient(to bottom, #fff 50%, #b8f9fd 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Title";
    letter-spacing: 4px;
    /*font-style: italic;*/
    line-height: 85px;
  }
  .date {
    position: absolute;
    top: 30%;
    left: 1%;
    font-size: 14px;
    width: 170px;
    .sun {
      width: 24px;
      height: 24px;
      vertical-align: bottom;
    }
    span {
      margin-left: 10px;
    }
  }
  .weather {
    position: absolute;
    top: 34%;
    z-index: 99;
    right: 17.3%;
    font-size: 14px;
    width: 170px;
    height: 40px;
    span {
      margin-right: 10px;
      float: left;
    }
  }
  .user {
    position: absolute;
    top: 30%;
    z-index: 99;
    right: 3%;
    font-size: 14px;
    width: 170px;
    height: 40px;
    cursor: pointer;
    img {
      width: 30px;
      height: 30px;
    }
    span {
      position: relative;
      top: -8px;
      left: 6px;
    }
  }
  .backs {
    position: absolute;
    top: 34%;
    right: 3%;
    font-size: 16px;
    cursor: pointer;
    font-family: "mokuaiTitile";
    background: linear-gradient(to bottom, #fff 50%, #b8f9fd 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
#main {
  // position: relative;
  // z-index: 10;
  width: 100%;
  height: 100%;
  margin-top: -5%;
  background-image: url(../../../assets/index/bg2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // pointer-events: all;
}
#myMap {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  // pointer-events: none;
  .iframeBox {
    width: 100%;
    height: 100%;
    border: none;
  }
}
#mainBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  background-image: url(../../../assets/index/bg2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  pointer-events: none;
}
.between /deep/.el-range-input {
  background-color: transparent;
}
.between /deep/ .el-date-editor .el-range-input {
  color: #fff;
  font-size: 12px;
}
.between /deep/ .el-range-editor.el-input__inner {
  
  background-color: rgba(14,51,59,0.5);
  width: 50%;
  height: 32px;
  border: #2b8e85 1px solid;
}
/deep/ .el-date-editor .el-range__icon {
  line-height: 20px;
}
.translations {
  transform: translateX(-100%);
}
.translations2 {
  transform: translateX(100%);
}
#lefts {
  width: 25%;
  height: 99%;
  position: absolute;
  transition: transform 1s;
  left: 20px;
  bottom: 0;
  z-index: 47;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 50px;
}
.center {
  width: 70%;
  height: 95vh;
  margin-top: 11vh;
  /* display: flex;
    flex-direction: column; */
  position: absolute;
  left: 50%;
  bottom: -3vh;
  margin-left: -34.5%;
  .between {
    position: absolute;
    bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 17%;
    pointer-events: all;
    .villageInfo {
      position: relative;
      height: 280px;
    }
  }
}
#rights {
  width: 25%;
  height: 99%;
  position: absolute;
  transition: transform 1s;
  bottom: 0;
  right: 14px;
  z-index: 47;

  background-size: 97% 96%;
  background-repeat: no-repeat;
  background-position: 0 50px;
}
.bottoms {
  width: 45%;
  height: 90px;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%, -50%);

  filter: blur(10px);
}
.leftBoxs {
  width: 92%;
  height: 94vh;
  margin-top: 11%;
  margin-left: 6%;
  pointer-events: all;
}
.rightBox {
  width: 92%;
  height: 94vh;
  margin-top: 11%;
  margin-right: 6%;
  pointer-events: all;
  .villageInfo {
    height: 26vh;
  }
  .survey {
    height: 34vh;
    position: relative;
    .equitment {
      img {
        width: 0.3rem;
        height: 0.3rem;
      }
    }
  }
  .partyBox {
    padding: 10px;
    height: 240px;
  }
}
.villageInfo {
  width: 100%;
  height: 26vh;
 /* overflow: hidden;*/

  .jidi {
    display: flex;
    justify-content: space-between;
    .jidi-text {
      width: 42%;
      font-size: 15px;
      color:rgba(255,255,255,.7);
      line-height: 20px;
      max-height:170px;
      overflow: auto;
    }
    /deep/.el-carousel__indicator.is-active button {
      width: 30px;
      border-radius: 4px;
      background-color: #80f6fa;
    }
  }
}

.carousel-detail {
  width: 40%;
  height: 200px;
  margin-top: 5px;
  border-radius: 8px;
  img {
    width: 100%;
    height: 90%;
    border-radius: 8px;
  }
}
.arrow {
  margin-top: 10px;
  padding: 10px;
  background-image: url(../../../assets/index/chkded.png);
  background-size: 100% 92%;
  background-repeat: no-repeat;
  .jidi-text {
    width: 58% !important;
    display: flex;
    flex-direction: column;
    .update {
      color: #66ffff;
      font-size: 16px;
    }
    .equit {
      margin: 8px 0 2px 8px;
      .arrow-bg {
        position: relative;
        top: 3px;
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url(../../../assets/index/arrow.png);
        background-size: 100% 100%;
      }
      .arrow-key {
        margin-left: 8px;
        font-size: 15px;
        color: #c3fffc;
      }
      .arrow-val {
        font-size: 14px;
        color: #fff;
        margin-left: 5px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 55%;
        height: 16px;
      }
    }
  }
}
.carousel {
  width: 55%;
  // height: 26vh;
  margin-top: 5px;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #80f6fa;
  }
  /deep/.el-carousel__button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #979797;
  }
}
.el-carousel {
  // height: 26vh;
}
.el-carousel /deep/.el-carousel__container {
  height: 18vh;
}
.el-carousel__item {
  background-color: transparent;
}
.infos {
  width: 100%;
  height: 180px;
  margin-top: 5px;

  background-size: 100% 100%;
  overflow: hidden;
}
.infoTitle {
  width: 35%;
  height: 35px;
  text-align: center;
  margin: 10px auto 0;
  line-height: 35px;
  font-size: 18px;
  font-family: "mokuaiTitile";
}
.villageFont {
  width: 95%;
  height: 110px;
  margin: 15px auto 0;
  font-size: 14px;
  font-family: "mokuaiXi";
  letter-spacing: 1px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.boxTitile {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  background: url("../../../assets/index/boxtitle.png") no-repeat;
  background-size: 100% 100%;
  font-size: 22px;
  font-family: "mokuaiTitile";
  font-weight: bold;
  line-height: 45px;
  font-style: normal;

  span {
    width: 85%;
    display: inline-block;
    margin-left: 6%;
    background: linear-gradient(to bottom, #fff 40%, #97fcff 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .choose {
    color: #c3fffc;
    font-size: 16px;
  }
  .el-input__inner {
    width: 160px;
  }
  .el-input__suffix {
    right: 20px;
  }
}
.base-select {
  width: 40%;
  margin-right: 10px;
  /deep/ .el-input__inner {
    background-color: rgba(14,51,59,0.5);
    border: 1px solid #113f44;
    color: #fff;
    font-size: 14px;
  }
}

.common-box {
  width: 100%;
  // height: 90px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  .land-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: auto;
    height: 260px;
    .equitment {
      width: 205px;
      height: 65px;
      display: flex;
      margin-bottom: 5px;
      .land-value {
        display: flex;
        flex-direction: column;
        width: 70%;
        .text-gray {
          padding-left: 10px;
          text-align: left;
          line-height: 34px;
          background: url(../../../assets/index/poline.png) repeat-x 100% 100%;
        }
        .text-white {
          padding-top: 5px;
          padding-left: 10px;
          text-align: left;
        }
        .number {
          font-size: 16px;
        }
      }
      .land-icon {
        width: 0.3rem;
        height: 0.3rem;
        margin-right: 0.03rem;
      }

      .text-gray {
        font-size: 16px;
        color: #b1e1ff;
      }
      .number {
        font-size: 26px;
        color: #fefefe;
      }
      .unit {
        font-size: 16px;
        color: #aac2d2;
      }
    }
  }
}

.surveyBox {
  &:extend(.common-box);
  background: url("../../../assets/index/equipment.png") no-repeat;
  background-size: 90% 90%;
}
.guan {
  flex-wrap: wrap;
  padding: 10px 8px;
  height: 215px;
  overflow: auto;
  .guan-list {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    width: 48%;
    margin-bottom: 5px;
    box-sizing: border-box;
    height: 100px;
    background: url("../../../assets/index/guan.png") no-repeat;
    background-size: cover;
    .content{
      width: 100%;
      padding:0 10px;
      .switch {
        padding-top: 15px;
        display: flex;
        justify-content: space-between;
        span {
          display: inline-block;
          width: 48%;
          text-align: center;
          cursor: pointer;
          height: 30px;
          line-height: 30px;
          font-size:14px;

        }
        .clsBtn{
          background: url("../../../assets/index/close.png") no-repeat;
          background-size:contain;
          background-position: right;

        }
        .openBtn{
          background: url("../../../assets/index/open.png") no-repeat;
          background-size: contain;
          background-position: left;
        }

      }
    }

    .guaname {
      font-size: 16px;
      color: #fff;
    }
    .number {
      font-size: 18px;
    }
    .accont {
      font-size: 16px;
    }
  }
}

.holdsAll {
  width: 33%;
  height: 70px;
  text-align: center;
  margin-left: 0.5rem;
}
.moneyAll {
  width: 33%;
  height: 70px;
  text-align: center;
}
.peopleAll {
  width: 33%;
  height: 70px;
  text-align: center;
}
.areaAll {
  width: 33%;
  height: 70px;
  text-align: center;
}
.land {
  padding: 10px 10px 15px;
  /deep/.el-input__inner {
    height: 32px;
  }
  .land-list {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: auto;
    height: 180px;
    .equitment {
      width: 205px;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      div {
        flex: 1;
        text-align: center;
      }
      .text-gray {
        font-size: 15px;
        color: #b1e1ff;
        background: url(../../../assets/index/equitment.png) no-repeat 100% 100%;
      }
      .text-white {
        background: url(../../../assets/index/equitment-right.png) no-repeat 100%
          100%;
      }
      .number {
        font-size: 18px;
        color: #fefefe;
      }
      .unit {
        font-size: 16px;
        color: #aac2d2;
      }
    }
    .equitment:last-child{
      // width: 300px;
      .number{
        font-size: 16px;
      }
    }
  }
  .choose {
    color: #c3fffc;
    font-size: 16px;
  }
  .el-input__inner {
    width: 160px;
  }
  .el-input__suffix {
    right: 20px;
  }
}
.surveyBox p {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "mokuaiTitile";
}
.surveyBox p:nth-child(odd) {
  margin-top: 20px;
}
.surveyBox span {
  font-size: 28px;
  /*  background: linear-gradient(to bottom, #fff 40%, #97fcff 60%); */
  color: #fff;
  -webkit-background-clip: text;
  /*   -webkit-text-fill-color: transparent; */
  font-family: "number";
  margin-right: 5px;
}
.surveyBox .total-color {
  color: #33f19f;
}
.partyBuilding {
  width: 100%;
  height: 30vh;
  position: relative;
}
.partyBox {
  width: 100%;
  height: 26vh;
  margin-top: 5px;
}
.leftCard {
  width: 100%;
  height: 225px;
}
.member-card {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  height: 34px;
}
.card-bg {
  background: url(../../../assets/index/alarm.png) repeat-x;
}
.member-bg {
  background: url(../../../assets/index/alarm-box.png) repeat-x;
}
.memberTop {
  font-size: 12px;
  line-height: 40px;
}
.memberNum {
  font-size: 24px;
  font-weight: bold;
  margin-left: 68px;
  line-height: 5px;
  font-family: "number";
}
.memberAll {
  width: 17%;
  height: 20px;
}
.fullMember {
  width: 33%;
  height: 20px;
}
.memberTwo {
  width: 25%;
  height: 20px;
  .alarm-num {
    color: #ff2424;
  }
}
.alarm-scroll {
  height: 180px;
  overflow: auto;
}
.distribution {
  width: 65%;
  height: 225px;
  float: left;
}
.bution {
  width: 100%;
  height: 28px;
  div {
    font-size: 18px;
    margin-left: 20px;
    /*  line-height: 30px; */
    float: left;
    margin-top: -6px;
    font-weight: bold;
    background: linear-gradient(to bottom, #fff 40%, #97fcff 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: italic;
  }
}
#echarts1 {
  width: 100%;
  height: 175px;
  margin-top: 10px;
}
.links {
  width: 20%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  background: url("../../../assets/index/detail-btn.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 15px;
  font-family: "mokuaiXi";
  cursor: pointer;
}
.bigData {
  width: 100%;
  height: 235px;
  position: relative;
}
.dataBox {
  width: 100%;
  height: 190px;
  margin-top: 10px;
}
#echarts2 {
  width: 100%;
  height: 100%;
}
.governance {
  width: 100%;
  height: 235px;
  position: relative;
}
.goverBox {
  width: 100%;
  height: 190px;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.goverBox p {
  font-size: 14px;
  // font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 20px;
}
.goverBox p:nth-child(even) {
  margin-top: -2px;
}
.goverBox span {
  font-size: 30px;
  color: #80f6fa;
  margin-right: 5px;
  font-family: "number";
}
.goverItem {
  width: 40%;
  height: 65px;
  background: url("../../../assets/index/allhu.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.goverItem2 {
  width: 40%;
  height: 65px;
  background: url("../../../assets/index/allren.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.goverItem3 {
  width: 40%;
  height: 65px;
  background: url("../../../assets/index/changren.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.goverItem4 {
  width: 40%;
  height: 65px;
  background: url("../../../assets/index/liuren.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.thirdAsset {
  width: 100%;
  height: 235px;
  position: relative;
}
.thirdBox {
  width: 100%;
  height: 190px;
  margin-top: 10px;
}
#echarts3 {
  width: 100%;
  height: 100%;
}
.authority {
  width: 100%;
  height: 235px;
  position: relative;
}
.authorityBox {
  width: 100%;
  height: 190px;
  margin-top: 10px;
}
#echarts4 {
  width: 100%;
  height: 100%;
}
.controlButton {
  width: 20%;
  height: 30px;
  position: fixed;
  bottom: 2%;
  left: 40%;
  display: flex;
  justify-content: space-between;
}
.rotating {
  width: 25%;
  height: 30px;
  // border: #2ab3b8 1px solid;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  background-color: #1e4c54;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 3px #2ab3b8;
  box-sizing: border-box;
}
// .rotating:hover{
//   background-color: #1E4C54;
// }
.dimensions {
  width: 25%;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
  background-color: #1e4c54;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 3px #2ab3b8;
}
// .dimensions:hover{
//   background-color: #1E4C54;
// }
.threeD {
  color: #fff;
  background-color: #979797;
}
.imgBigBox {
  width: 95%;
  height: 85%;
  border: #2ab3b8 1px solid;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(28, 109, 123, 0.7);
  border-radius: 10px;
  z-index: 999;
}
.popUpTop {
  width: 100%;
  height: 50px;
  font-family: "mokuaiTitile";
  font-size: 20px;
  font-style: italic;
  line-height: 50px;
  background: url("../../../assets/villageRural/tanTop.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  span {
    margin-left: 65px;
  }
  .closeCss {
    margin-left: 0;
    float: right;
    top: 5px;
    right: 20px;
    position: absolute;
    font-size: 30px;
    cursor: pointer;
  }
  img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
.imgInfo {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #0e343e;
  border: 1px solid #17504d;
}
.carouselBig {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  .box {
    margin: auto;
  }
  .el-carousel {
    height: 100%;
  }
  .el-carousel /deep/.el-carousel__container {
    height: 100%;
  }
  .el-carousel__item {
    background-color: transparent;
  }
}
/deep/.el-select-dropdown {
  background-color: #0e333b !important;
}
/deep/.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #0e333b;
}
.el-select-dropdown__item {
  padding-left: 10px;
}
.el-select-dropdown__item.hover {
  background-color: #0e2325;
}
.middle-text{
  position: absolute;
  width: 90px;
  height: 90px;
  background: #124C5A;
  border-radius: 50%;
  top: 43.5%;
  left: 43%;
  text-align: center;
  font-size: 14px;
  padding-top: 30px;
}
/deep/ .dv-scroll-board{
  margin-top: 10px;
}
/deep/ .dv-scroll-board .header{
  height: auto;
  background: url(../../../assets/index/alarm.png) repeat-x;
  background-color: transparent !important;
  background-size: cover;
  margin: auto;
  width: 100%;
}
/deep/ .dv-scroll-board .rows .row-item{
  background: url(../../../assets/index/alarm-box.png) repeat-x;
  background-color: transparent !important;
  background-size: cover;
}
.link-btn{
    position: absolute;
    top: 107px;
    width: 600px;
    display: flex;
    justify-content: space-between;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}
.link-btn-item{
  border: 1px solid rgba(255,255,255,0.5);
  width: 80px;
  height: 30px;
  line-height: 30px;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  background: linear-gradient(to bottom, #16494A, #1A8E97);
  box-shadow: 0 0 0 2px rgba(255,255,255,0.3);
  border-top: 0;
  cursor: pointer;
}
</style>

